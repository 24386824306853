<template>
  <div class="myCollect">
    <div v-loading="loading"></div>
    <div>
      <div class="collect-nav">
        <ul class="flex">
          <li v-for="(item,index) in navList" :key="index" @click="navActive0(index)" class="mr flex0 cur" :class="index == navActive ? 'active2' : ''">{{item}}</li>
        </ul>
      </div>
      <div class="hr"></div>
      <div v-if="!Isdata" class="collect-content">
        <div v-for="(item, index) in collectContent.data" :key="index" class="collect-item">
          <h1><router-link :to="item.route">{{ item.title }}</router-link> </h1>
          <div class="collect-item-date">
            <span>发布于{{item.releaseTimeStr}}</span>
          </div>
          <p class="collect-item-text"> {{ item.introduction }} </p>
          <div class="tags flex4">
            <div v-for="(items,index) in item.tags" :key="index" class="tag-item">{{ items }}</div>
          </div>
          <div class="collectBtn flex">
            <div v-if="navActive != 2 && navActive != 3" class="btn-item flex0 cur btn-Like" @click="IsLikes(index, item.id, item.isPraise)" title="点赞">
              <img v-if="item.isPraise" src="@/assets/PC/fenceDetail/praise1.png" alt="">
              <img v-else src="@/assets/PC/fenceDetail/praise.png" alt="">
              <span class="ml"> {{ item.praiseNum }} </span>
            </div>
            <div v-if="navActive == 2 || navActive == 3" class="btn-item flex0 cur btn-collect" title="浏览量">
              <img src="@/assets/PC/studyF/study_type_k.png" alt="">
              <span class="ml"> {{ item.pv }} </span>
            </div>
            <div class="btn-item flex0 cur btn-collect" @click="Enshrines(item.id)" title="收藏">
              <img src="@/assets/PC/fenceDetail/collect1.png" alt="">
              <span v-if="navActive != 3" class="ml"> {{ item.collectionNum }} </span>
            </div>
            <div v-if="navActive == 1" class="btn-item flex0 btn-comment" title="评论">
              <img src="@/assets/PC/fenceDetail/comment.png" alt="">
              <span class="ml"> {{ item.comment }} </span>
            </div>
            <div v-if="navActive != 3" class="btn-item flex0 cur btn-share share" ref="seatTipOperation" title="转发">
              <img src="@/assets/PC/fenceDetail/share.png" alt="">
              <span class="ml"> {{ item.sharedNum }} </span>
              <div class="shareCode">
                <p><img src="@/assets/PC/forum/icon_copy.png" alt=""><span v-clipboard:copy="item.link" v-clipboard:success="onCopy">复制链接</span></p>
                <p @click="weibo(item.link,item.title, item.id)"><img src="@/assets/PC/forum/icon_sina.png" alt=""><span>新浪微博</span></p> 
                <p v-if="clientWidth > 750"><img src="@/assets/PC/forum/icon_wechat.png" alt=""><span>微信扫一扫</span></p>
                <div v-if="clientWidth > 750" class="qrCode" :id="'qrCode'+ index" ref="qrCodeDiv"></div>
                <div class="san0"></div>
              </div>
            </div>
          </div>
          <div class="hr"></div>
        </div>
      </div>
      <div v-else class="noData">
        <img src="@/assets/PC/profile/listNone.png" alt="" />
      </div>
      <div v-if="IsloadList" class="loadList flex0">
        <div class="loadList0" @click="loadlist">加载更多</div>
      </div>
      <div v-if="clientWidth < 750 && !IsloadList && collectContent.data.length > 0" class="tc notDetail">
        <div>
          没有更多内容了~ 
          <span class="violet" @click="scrollTop">返回顶部</span>
        </div>
      </div>
      <div class="page tr">
        <el-pagination
          @current-change="handleCurrentChange"
          :total="collectContent.recordCount"
          :page-size="collectContent.pageSize"
          :current-page="collectContent.pageIndex"
          background
          layout="pager, next"
          next-text="下一页"
          hide-on-single-page
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from "@/api/profile.js";
import * as forum from "@/api/forum.js";
	import { debounce,GetWxShares } from "@/utils/common";
import * as Fence from "@/api/library.js";
import { KeenUnCollect, UnActityShowCollect } from "@/api/KeenMind.js";
import QRCode from 'qrcodejs2';
import { dataState } from "@/api/all.js";

import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "myCollect",
  data() {
    return {
      loading: true,                     // !加载动画
      navList: ['习剑', '驭剑', '剑鸣','作品', '入门学习指导'],  // !Tab切换
      navActive: 0,                      // !当前选中模块
      collectContent: {
        data: [],                        // !数据list
        recordCount: 0,                  // !总条数
        pageIndex: 1,                    // !当前页数
        pageSize: 3,                     // !每页条数
      },
      mycollects: {
        page: 1,                         // !页码
        limit: 3,                        // !条数
      },
      local: '',                         // !复制链接地址
      IsloadList: false,                  // !移动端加载更多
      Isdata: false,                      // !暂无数据
    };
  },
  mounted(){
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    this.GetCollectionLibrarys();
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "myCollect",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    // !顶部切换
    navActive0(index){
      this.loading = true;
      this.navActive = index;
      this.mycollects.page = 1;
      if(this.clientWidth > 750){
        Array.from(document.querySelectorAll(".qrCode")).map(v=>{
          v.innerHTML = '';
        });
      }
      
      if (this.navActive == 0) {
        this.GetCollectionLibrarys();
      } else if (this.navActive == 1) {
        this.GetCollectionCommunitys();
      } else if (this.navActive == 2) {
        this.GetCollectionActivitys();
      } else if (this.navActive == 3) {
        this.loading = false;
        this.GetCollectionActivityShows();
      } else if (this.navActive == 4) {
        this.loading = false;
        this.GetCollectionIntroLearn();
      }
    },
    // !习剑收藏
    GetCollectionLibrarys(Ismobile){
      profile.GetCollectionLibrary(this.mycollects).then( res => {
        if(res.status == 1) {
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.collectContent.data = this.collectContent.data.concat(res.data);
              if(this.collectContent.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.collectContent = res;
            }
          }else {
            this.collectContent = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          res.data.map((item, index)=>{
            item.link = ApiToken.shareUrl + 'Home/FenceDetail?id='+ item.id;
            item.route = '/Home/FenceDetail?id='+ item.id;
            if(this.clientWidth > 750){
              this.$nextTick(function () {
                this.bindQRCode(item.link,'qrCode'+index);
              })
            }
          })
        }else {
          this.$$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !驭剑接口
    GetCollectionCommunitys(Ismobile) {
      profile.GetCollectionCommunity(this.mycollects).then(res =>{
        if(res.status == 1) {
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.collectContent.data = this.collectContent.data.concat(res.data);
              if(this.collectContent.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.collectContent = res;
            }
          }else {
            this.collectContent = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          res.data.map((item, index)=>{
            item.link = ApiToken.shareUrl + 'Home/forumDetails?id='+ item.id;
            item.route = '/Home/forumDetails?id='+ item.id;
            if(this.clientWidth > 750){
              this.$nextTick(function () {
                this.bindQRCode(item.link,'qrCode'+index);
              })
            }
          })
        } else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !剑鸣接口
    GetCollectionActivitys(Ismobile) {
      profile.GetCollectionActivity(this.mycollects).then(res =>{
        if(res.status == 1) {
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.collectContent.data = this.collectContent.data.concat(res.data);
              if(this.collectContent.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.collectContent = res;
            }
          }else {
            this.collectContent = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          res.data.map((item, index)=>{
            item.link = ApiToken.shareUrl + 'Home/keenMindDetail?id='+ item.id;
            item.route = '/Home/keenMindDetail?id='+ item.id;
            if(this.clientWidth > 750){
              this.$nextTick(function () {
                this.bindQRCode(item.link,'qrCode'+index);
              })
            }
          })
        } else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !作品接口
    GetCollectionActivityShows(Ismobile) {
      profile.GetCollectionActivityShow(this.mycollects).then(res =>{
        if(res.status == 1) {
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.collectContent.data = this.collectContent.data.concat(res.data);
              if(this.collectContent.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.collectContent = res;
            }
          } else {
            this.collectContent = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          res.data.map(item => {
            item.route = '/Home/worksDetail?id='+ item.id;
          })
        } else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !入门学习指导接口
    GetCollectionIntroLearn(Ismobile) {
      profile.GetCollectionIntroLearn(this.mycollects).then( res => {
        if(res.status == 1) {
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            } else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.collectContent.data = this.collectContent.data.concat(res.data);
              if(this.collectContent.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.collectContent = res;
            }
          }else {
            this.collectContent = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
          res.data.map((item, index)=>{
            item.link = ApiToken.shareUrl + 'Home/FenceDetail?id='+ item.id + "&isnewpartner=new";
            item.route = '/Home/FenceDetail?id='+ item.id + "&isnewpartner=new";
            if(this.clientWidth > 750){
              this.$nextTick(function () {
                this.bindQRCode(item.link,'qrCode'+index);
              })
            }
          })
        }else {
          this.$$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !分页
    handleCurrentChange(currentPage){
      Array.from(document.querySelectorAll(".qrCode")).map(v=>{
        v.innerHTML = '';
      });
      this.loading = true;
      this.mycollects.page = currentPage;
      if (this.navActive == 0) {
        this.GetCollectionLibrarys();
      } else if (this.navActive == 1) {
        this.GetCollectionCommunitys();
      } else if (this.navActive == 2) {
        this.GetCollectionActivitys();
      } else if (this.navActive == 3) {
        this.GetCollectionActivityShows()
      } else if (this.navActive == 4) {
        this.GetCollectionIntroLearn()
      }
    },
    // !移动端加载更多
    loadlist() {
      if(this.clientWidth < 750){
        this.loading = true;
        this.mycollects.page++;
        if (this.navActive == 0) {
          this.GetCollectionLibrarys(true);
        } else if (this.navActive == 1) {
          this.GetCollectionCommunitys(true);
        } else if (this.navActive == 2) {
          this.GetCollectionActivitys(true);
        } else if (this.navActive == 3) {
          this.GetCollectionActivityShows(true);
        } else if (this.navActive == 4) {
          this.GetCollectionIntroLearn(true);
        }
      }
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // !点赞
    IsLikes(index, id,isPraise){
      if(this.$store.state.token) {
        debounce(() => {
          if(!isPraise){
            if(this.navActive == 0) {
              const data = { resourcesId: id, type: 0 };
              Fence.Praise(data).then((res) => {
                this.$toast(res.message);
                if(res.status == 1) {
                  this.collectContent.data[index].isPraise = true;
                  this.collectContent.data[index].praiseNum += 1;
                }
              })
            } else if (this.navActive == 1) {
              const data =  { forumManagementId: id };
              forum.likeArticle(data).then((res) => {
                if(res.status == 1) {
                  if(res.message != 0) {
                    this.$toast("点赞成功！+" + res.message + "积分")
                  } else {
                    this.$toast("点赞成功！")
                  }
                  this.collectContent.data[index].isPraise = true;
                  this.collectContent.data[index].praiseNum += 1;
                } else {
                  this.$toast(res.message);
                }
              })
            } else if (this.navActive == 4) {
              const data = { resourcesId: id, type: 0 };
              Fence.Praise(data).then((res) => {
                this.$toast(res.message);
                if(res.status == 1) {
                  this.collectContent.data[index].isPraise = true;
                  this.collectContent.data[index].praiseNum += 1;
                }
              })
            }
          }else {
            if(this.navActive == 0) {
              const data = { resourcesId: id, type: 0 };
              Fence.UnPraise(data).then((res) => {
                this.$toast(res.message);
                if(res.status == 1) {
                  this.collectContent.data[index].isPraise = false;
                  this.collectContent.data[index].praiseNum -= 1;
                }
              })
            } else if (this.navActive == 1) {
              const data =  { forumManagementId: id };
              forum.likeCancelArticle(data).then((res) => {
                this.$toast(res.message);
                if(res.status == 1) {
                  this.collectContent.data[index].isPraise = false;
                  this.collectContent.data[index].praiseNum -= 1;
                }
              })
            } else if (this.navActive == 4) {
              const data = { resourcesId: id, type: 0 };
              Fence.UnPraise(data).then((res) => {
                this.$toast(res.message);
                if(res.status == 1) {
                  this.collectContent.data[index].isPraise = false;
                  this.collectContent.data[index].praiseNum -= 1;
                }
              })
            }
          }
        }, 1000)
      }else {
        this.$toast('请登录后操作!')
      }
    },
    // !取消收藏
    Enshrines(id) {
      if(this.$store.state.token) {
        if(this.navActive == 0){
          const data = { libraryId: id };
          Fence.UnCollect(data).then( res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.mycollects.page = 1;
              if(this.clientWidth > 750) {
                Array.from(document.querySelectorAll(".qrCode")).map(v=>{
                  v.innerHTML = '';
                });
              }
              this.GetCollectionLibrarys();
            } else {
              location.reload();
            }
          })
        } else if (this.navActive == 1) {
          const data = { forumManagementId: id };
          forum.collectCancelArticle(data).then(res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.mycollects.page = 1;
              if(this.clientWidth > 750) {
                Array.from(document.querySelectorAll(".qrCode")).map(v=>{
                  v.innerHTML = '';
                });
              }
              this.GetCollectionCommunitys();
            } else {
              location.reload();
            }
          })
        } else if (this.navActive == 2) {
          const data = { activityId: id };
          KeenUnCollect(data).then(res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.mycollects.page = 1;
              if(this.clientWidth > 750) {
                Array.from(document.querySelectorAll(".qrCode")).map(v=>{
                  v.innerHTML = '';
                });
              }
              this.GetCollectionActivitys();
            } else {
              location.reload();
            }
          })
        } else if (this.navActive == 3) {
          const data = { activityShowId: id };
          UnActityShowCollect(data).then(res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.mycollects.page = 1;
              this.GetCollectionActivityShows();
            } else {
              // location.reload();
            }
          })
        } else if (this.navActive == 4) {
          const data = { libraryId: id };
          Fence.UnCollect(data).then( res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.mycollects.page = 1;
              if(this.clientWidth > 750) {
                Array.from(document.querySelectorAll(".qrCode")).map(v=>{
                  v.innerHTML = '';
                });
              }
              this.GetCollectionIntroLearn();
            } else {
              location.reload();
            }
          })
        }
      } else {
        this.$toast('请登录后操作!')
      }
    },
    // !分享微博
    weibo(link, titles, id){
      if(this.navActive == 0){
        this.AddIntegrals(2, id);
        this.EventLogs(2, id);
      }else {
        const Id = { forumManagementId: id };
        forum.shareNum(Id).then((res) => {});
      }
      const title = titles;
      let url = link;
      url = encodeURIComponent(url);
      var a = screen.width / 2 - 300,
          d = screen.height / 2 - 175;
      window.open('http://v.t.sina.com.cn/share/share.php?title=' + title + '&url=' + url, "Ignite", "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,width=800,height=530,top=" + d + ",left=" + a);
    },
    // !二维码生成
    bindQRCode(link,id) {
      new QRCode(id, {
        text: link + '&code='+ Math.random(),
        width: 110,
        height: 110,
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    // !复制链接
    onCopy(){
      this.$toast({message: '复制成功!',duration: 1000,})
    },
    // !播放视频、分享资源
    EventLogs(type, id){
      const log = {
        resourcesId: id,
        eventType: type,
      };
      Fence.AddEventLogs(log).then((res) => {});
    },
    // !增加积分、金币
    AddIntegrals(type, id){
      const data = {'resourcesId': id, 'integralType': type};
      Fence.AddIntegral(data).then(res=>{
        if (res.status == 1) {
          if(res.message > 0){
            this.$toast('+'+ res.message + ' 积分');
          }
        }else{
          this.$toast(res.message);
        }
      });
    },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .myCollect{
    height: auto;padding: 40px 40px 80px 40px;
    .collect-nav ul li {padding: 0 15px; height: 32px;border-radius: 3px;background: #F2F5F7;font-size: 16px;}

    .collect-item h1{font-size: 16px;margin-bottom: 12px;}
    .collect-item-date {color: #738294;font-size: 15px;margin-bottom: 16px;}
    .collect-item-text {line-height: 26px;font-size: 15px;color: #738294;margin-bottom: 20px;}
    .tags{display: none;}

    .btn-item{margin-right: 28px;}
    .btn-Like img{width: 17px;height: 17px;}
    .btn-collect img{width: 17px;height: 21px;}
    .btn-comment img{width: 18px;height: 18px;}
    .btn-share img{width: 20px;height: 20px;}
    
    .share {position: relative;}
    .shareCode p{text-align: center;line-height: 2.5;display: flex;align-items: center;}
    .shareCode p img {margin-right: 5px;}
    .shareCode p:hover{color: #6B18A8; font-weight: bold;}
    .shareCode{width: 156px;height: 260px;background: #fff;border: 1px #ccc solid;position: absolute;top: -265px;left: -60px;padding: 10px 20px;border-radius: 5px;box-shadow: 0 0 20px #999;display: none; }
    .shareCode .qrCode{width: 110px;height: 110px;background: #ccc;margin: 0 auto;margin-top: 10px;}
    .san0{position: absolute;width: 0;height: 0;border: 7.5px solid transparent;border-top-color: #fff; left: 68px; bottom: -15px;}

    .share:hover .shareCode{display: block;}
  }
    
  @media (max-width: 750px) {
    .myCollect{
      height: auto;padding: vw(40);
      .collect-nav ul {flex-wrap: wrap;}
      .collect-nav ul li {padding: 0 vw(30);}
      .collect-nav ul li:last-child {margin-top: vw(10);}
      .collect-item h1{font-size: vw(28);margin-bottom: vw(14);}
      .collect-item-date {span{font-size: vw(20);}}
      .collect-item-text {line-height: vw(34);font-size: vw(24)}
      .tags{margin-bottom: vw(20);display: flex;}

      .btn-comment {display: none;}
      .btn-Like img {width: vw(24); height: vw(24);}
      .btn-share img {width: vw(30); height: vw(30);}
      .btn-collect img {width: vw(28);height: vw(34);}
      .shareCode{height: vw(190);top: vw(-200);}
      
      .san0 {bottom: vw(-28);}
      .hr {margin: vw(30) 0;}
    }
  }
</style>